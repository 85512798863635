import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LinkItem from './LinkItem';

const TopLinkItem = ({ id, title, href, className }) => (
  <div className={classNames('navlist__item')}>
    <LinkItem
      id={id}
      title={title}
      key={id}
      href={href}
      className={className}
    />
  </div>
);

TopLinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TopLinkItem.defaultProps = {
  className: '',
};

export default TopLinkItem;
