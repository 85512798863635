import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { alert } from '@utils/Dialogs';
import {
  sendCancelAbandonedCartRequestAPI,
  sendRestoreOldAbandonedCartRequestAPI,
} from '@jsv3/utils/api/cartAPI';
import CartItemsModal from '@jsv3/components/molecules/CartItemsModal';
import Button from '@jsv3/components/atoms/Button';

/**
 * @param {object} cart
 * @return {JSX.Element}
 * @constructor
 */
const RestoreAbandonedCartModal = ({ cart }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsModalOpened(true);
  }, []);

  /**
   * @return {void}
   */
  const onCancel = () => {
    setIsLoading(true);

    sendCancelAbandonedCartRequestAPI()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert(t('Some error occurred. Please try again later.'));
        setIsLoading(false);
      });
  };

  /**
   * @return {void}
   */
  const onRestore = () => {
    setIsLoading(true);

    sendRestoreOldAbandonedCartRequestAPI()
      .then(() => {
        window.location.href = '/checkout';
      })
      .catch(() => {
        alert(t('Some error occurred. Please try again later.'));
        setIsLoading(false);
      });
  };

  /**
   * @return {JSX.Element}
   */
  const renderFooterActions = () => (
    <div className="d-flex flex-horizontal-between">
      <Button
        className="layover-popup__button layover-popup__button--dark m-0 mr-20"
        onClick={onCancel}
        dataQaId="cancel_restore"
      >
        Cancel
      </Button>

      <Button className="layover-popup__button m-0" onClick={onRestore} dataQaId="submit_restore">
        Restore
      </Button>
    </div>
  );

  return (
    <CartItemsModal
      cartItems={cart.cart_items}
      isLoading={isLoading}
      dataQaId="qa_popup_restore_abandoned_cart"
      isModalOpened={isModalOpened}
      renderFooterActions={renderFooterActions}
      title="Would you like to restore your previous cart and complete your purchase?"
    />
  );
};

RestoreAbandonedCartModal.propTypes = {
  cart: PropTypes.object.isRequired,
};

export default ErrorBoundaryDecorator()(RestoreAbandonedCartModal);
