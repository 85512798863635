import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import apiClient from '@js/apiClient';
import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LabelComponent from '@js/landing/vipTravelerPromo2/auth/LabelComponent';

const FORM_STATUSES = {
  FORM: 'form',
  SUBMITTING: 'submitting',
  ONE_MORE_MESSAGE: 'one_more_message',
  ERROR: 'error',
};

const EmailForm = () => {
  const [currentStatus, setNewStatus] = useState(FORM_STATUSES.FORM);

  const handleErr = (error) => Logger.error(error);

  const combineMessage = (message, subject) => ({
    message,
    options: { email_conversation_subject: subject, email_template: 'text-template' },
    source_type: 'email',
    context_type: 'email_form',
  });

  const validateMessage = (message) => message.trim() !== '';

  const handleSubmit = (values, actions) => {
    const { message, subject } = values;

    if (validateMessage(message)) {
      setNewStatus(FORM_STATUSES.SUBMITTING);

      return apiClient
        .post('/conversation/reply', combineMessage(message, subject))
        .then(() => setNewStatus(FORM_STATUSES.ONE_MORE_MESSAGE))
        .catch((err) => {
          handleErr(err);
          setNewStatus(FORM_STATUSES.ERROR);
        });
    }

    actions.setFieldError('message', 'No message');

    return false;
  };

  const initialValues = {
    subject: '',
    message: '',
  };

  const renderOneMoreMessage = () => (
    <>
      <p>Your message has been sent.</p>
      <button
        type="button"
        onClick={() => setNewStatus(FORM_STATUSES.FORM)}
        className="btn-red communication-btn"
      >
        send one more message
      </button>
    </>
  );

  const renderErrorMessage = () => (
    <>
      <p>An error occurred. Your message hasn&apos;t been sent.</p>
      <p>Try later or contact our support team</p>
      <button
        type="button"
        onClick={() => setNewStatus(FORM_STATUSES.FORM)}
        className="btn-red communication-btn"
      >
        Try again
      </button>
    </>
  );

  const renderForm = () => (
    <div className="chat-email-wrapper">
      <div className="chat-email-inner-wrapper">
        <p>Use the form below to send an email to our general support team</p>

        <p>
          Or Upgrade to VIP+ to be paired with a personal travel stylist who you can email directly
        </p>

        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {() => (
            <Form className="stylist-feedback form">
              <div className="input form-field">
                <LabelComponent name="message" labelText={t('Subject')} />

                <Field
                  type="text"
                  name="subject"
                  className="subject-input"
                  placeholder={t('Enter Subject')}
                  autoComplete="off"
                />
              </div>

              <div className="input form-field">
                <LabelComponent name="message" labelText={t('Message')} />

                <Field
                  as="textarea"
                  name="message"
                  className="message-content"
                  placeholder={t('Enter Text')}
                  autoComplete="off"
                />
              </div>

              <button type="submit" className="btn-red communication-btn">
                send
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );

  switch (currentStatus) {
    case FORM_STATUSES.FORM:
      return renderForm();
    case FORM_STATUSES.SUBMITTING:
      return <Spinner />;
    case FORM_STATUSES.ONE_MORE_MESSAGE:
      return renderOneMoreMessage();
    case FORM_STATUSES.ERROR:
      return renderErrorMessage();
    default:
      return null;
  }
};

export default ErrorBoundaryDecorator()(EmailForm);
