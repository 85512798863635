import apiClient from '@jsv3/services/apiClient';

/**
 * Send ping request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendPingRequestAPI = () => apiClient.put('/cart/ping');

/**
 * Send "restore abandoned cart" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendRestoreAbandonedCartRequestAPI = () => apiClient.put('/cart/restore');

/**
 * Send "restore abandoned cart" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendRestoreOldAbandonedCartRequestAPI = () =>
  apiClient.post('/cart/restore-abandoned');

/**
 * Send "cancel abandoned cart" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendCancelAbandonedCartRequestAPI = () => apiClient.post('/cart/cancel-abandoned');

/**
 * Send request to add guests
 *
 * @param cartItemId {Number}
 * @param options {Array}
 *
 * @return {Promise}
 */
export const addGuestsRequestAPI = (cartItemId, options) =>
  apiClient.post(`cart/${cartItemId}/guests`, {
    cart_item_id: cartItemId,
    options: [options],
  });

/**
 * Send request to delete all unpaid fees
 *
 * @param cartItemId {Number}
 *
 * @return {Promise}
 */
export const deleteFeesRequestAPI = (cartItemId) => apiClient.delete(`cart/${cartItemId}/guests`);

/**
 * @param {object} params
 * @return {Promise}
 */
export const verifyCartRequestAPI = (params) => apiClient.post('/cart/verify', params);

/**
 * Send request to delete cartItem by id
 *
 * @param cartItemId {Number}
 *
 * @return {Promise}
 */
export const deleteCartItemRequestAPI = (cartItemId) => apiClient.delete(`/cart/${cartItemId}`);

/**
 * @param cartItemId
 * @param params
 * @return {Promise}
 */
export const addModifierAPI = (cartItemId, params) =>
  apiClient.post(`/cart/${cartItemId}/modifier`, params);

/**
 * @param cartItemId
 * @param data
 * @return {Promise}
 */
export const deleteModifierAPI = (cartItemId, data) =>
  apiClient.delete(`/cart/${cartItemId}/modifier`, {
    data,
  });
