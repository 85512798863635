import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import FindChat from '@jsv2/pages/TravelFindsPage/FindChat';
import { getUserHistoryApi } from '@jsv2/utils/CommunicationUtils/APIRequests';
import { setCommunicationDataAction, setUnreadMessagesCountAction } from '@js/ducks/communications';
import {
  EXPIRED_SESSION_IS_LIVE_CHAT_KEY,
  EXPIRED_SESSION_RELOADED_KEY,
} from '@jsv2/Enums/ExpiredSessionEnums';

const parsedUrl = new URL(window.location.href);
const toChat = parsedUrl.searchParams.get('to-chat');

/**
 * Live chat.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LiveChat = ({ show, unreadMessagesCount, setCommunicationData, setUnreadMessagesCount }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const renderBadgeTitle = () => {
    if (unreadMessagesCount > 0) {
      const counter = unreadMessagesCount > 9 ? '9+' : unreadMessagesCount;

      return <span className="live-chat__badge-counter">{counter}</span>;
    }

    return null;
  };

  const onToggleOpenChat = () => {
    setIsChatOpen((prevState) => !prevState);
  };

  const getUserStylist = () =>
    getUserHistoryApi()
      .then(({ data: { messages, active_stylist: stylist } }) => {
        setUnreadMessagesCount(messages);

        if (stylist) {
          const {
            email,
            phone,
            whatsapp_phone: whatsappPhone,
            display_name: stylistName,
          } = stylist;

          setCommunicationData('stylist', {
            email,
            phone,
            whatsappPhone,
            stylistName,
          });
        }
      })
      .catch((err) => Logger.error(err));

  useEffect(() => {
    getUserStylist();

    const isPageReloaded = localStorage.getItem(EXPIRED_SESSION_RELOADED_KEY) !== null;
    const expiredSessionIsLiveChatOpened =
      localStorage.getItem(EXPIRED_SESSION_IS_LIVE_CHAT_KEY) === 'true';

    if (expiredSessionIsLiveChatOpened && isPageReloaded) {
      setIsChatOpen(expiredSessionIsLiveChatOpened);

      localStorage.removeItem(EXPIRED_SESSION_IS_LIVE_CHAT_KEY);

      return;
    }

    if (toChat) {
      setIsChatOpen(true);
    }
  }, []);

  if (!show) {
    return null;
  }

  return (
    <>
      <div
        className={`live-chat ${isChatOpen ? 'live-chat--show' : 'live-chat--hide'}`}
        data-qa-id="live_chat_block"
      >
        <FindChat context_type="live_chat" isLiveChat={isChatOpen} isStickyChat />
      </div>

      <div
        className={`live-chat__badge-wrapper ${
          isChatOpen ? 'live-chat__badge-wrapper--close' : ''
        }`}
      >
        <div className="live-chat__mobile-header">
          <img src="/images/lp/promo2/logotype-black.svg" alt="Vip Traveler" />

          <span>Connect</span>
        </div>

        <button type="button" className="live-chat__badge" data-qa-id="live_chat_icon" onClick={onToggleOpenChat}>
          {renderBadgeTitle()}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ liveChat: { show }, communications: { unreadMessagesCount } }) => ({
  show,
  unreadMessagesCount,
});

const mapDispatchToProps = {
  setCommunicationData: setCommunicationDataAction,
  setUnreadMessagesCount: setUnreadMessagesCountAction,
};

export default ErrorBoundaryDecorator()(connect(mapStateToProps, mapDispatchToProps)(LiveChat));
