export const NOTIFICATION_CHANNELS = [
  {
    type: 'sms',
    title: 'SMS',
    icon: '/images/icons/sms-icon.svg',
    label: 'phone',
  },
  {
    type: 'whatsapp',
    title: 'WhatsApp',
    icon: '/images/icons/whatsapp-icon.svg',
    label: 'whatsapp',
  },
  {
    type: 'email',
    title: 'Email',
    icon: '/images/icons/email-icon.svg',
    label: 'email address',
  },
];
