import React from 'react';

const PARAMS = {
  title: 'Access forbidden',
  message: 'You are not authorized to access this page',
};

const Forbidden = () => (
  <>
    <div className="message_title">
      {PARAMS.title}
    </div>

    <div className="title">
      {PARAMS.message}
    </div>
  </>
);

export default Forbidden;
