import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import TopLinkItem from '@jsv2/components/Header/components/TopLinkItem';
import { getBackLinkToGo } from '@jsv3/utils/urlUtils';

/**
 * Render a link button "Plan a Trip"
 *
 * @return {*}
 *
 * @constructor
 */
const PlanATrip = ({ shouldGoBack }) => (
  <TopLinkItem
    id="plan-a-trip"
    title="Plan a Trip"
    href={'/plan-a-trip' + (shouldGoBack ? `?go_back_url=${getBackLinkToGo()}` : '')}
  />
);

PlanATrip.propTypes = {
  shouldGoBack: PropTypes.bool,
};

PlanATrip.defaultProps = {
  shouldGoBack: false,
};

export default ErrorBoundaryDecorator()(PlanATrip);
