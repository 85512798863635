import apiClient from '@jsv3/services/apiClient';

/**
 * @return {Promise<AxiosResponse<any>>}
 */
export function onSendVerificationChannel(type) {
  return apiClient.post('channel-verification/send-notification', { type });
}

/**
 * @return {Promise<AxiosResponse<any>>}
 */
export function onConfirmVerificationChannel(code) {
  return apiClient.post('channel-verification/confirm', { code });
}
