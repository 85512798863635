import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BlockMessages from '@components/Chat/BlockMessages';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const NUMBER_OF_VISIBLE_EMAIL_SYMBOLS = 90;

const ConversationHistory = ({ getHistory, communicationHistory }) => {
  const [isLoading, setIsLoading] = useState(false);

  const processEmailPreviewMessage = (emailMessage) => {
    const div = document.createElement('div');
    div.innerHTML = emailMessage;

    const content = div.querySelectorAll('p');

    let processedText = '';

    content.forEach((item) => {
      if (item.innerText !== '' && item.innerText !== ' ' && item.innerText !== '\n') {
        if (processedText.length === 0) {
          processedText = item.innerText;
        } else {
          processedText += '. ' + item.innerText;
        }
      }
    });

    return (processedText || emailMessage)
      .replace(/<\/?[^>]+(>|$)/g, '')
      .slice(0, NUMBER_OF_VISIBLE_EMAIL_SYMBOLS)
      .concat('...');
  };

  const messages = communicationHistory.map((item) => {
    if (item.source_type === 'email') {
      return { ...item, messagePreview: processEmailPreviewMessage(item.message) };
    }

    return item;
  });

  useEffect(() => {
    if (communicationHistory.length === 0) {
      setIsLoading(true);

      getHistory().then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <div className="history chat-wrapper">
      <BlockMessages messages={messages} isLoading={isLoading} isHistoryChat />
    </div>
  );
};

ConversationHistory.propTypes = {
  getHistory: PropTypes.func.isRequired,
};

const mapStateToProps = ({ communications: { communicationHistory } }) => ({
  communicationHistory,
});

export default ErrorBoundaryDecorator()(connect(mapStateToProps)(ConversationHistory));
