import moment from 'moment';
import { DATE_FORMAT } from '@jsv3/enums/momentEnums';

/**
 * @param value
 * @param dateFormat
 * @return {string}
 */
export function toUTCDate(value, dateFormat = DATE_FORMAT.FULL_5) {
  return moment.utc(value).format(dateFormat);
}
