import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { IMAGE_SIZES, PRODUCT_TYPES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { toUTCDate } from '@jsv3/utils/dateUtils';
import Price from '@js/components/Price';
import Image from '@jsv2/components/Image';
import DefaultModal from '@jsv3/components/organisms/DefaultModal';
import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';

/**
 * @param {array} cartItems
 * @param {boolean} isModalOpened
 * @param {boolean} isLoading
 * @param {string} dataQaId
 * @param {function} renderFooterActions
 * @param {string} title
 * @param {string} subtitle
 * @param {function} deleteCartItem
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const CartItemsModal = ({
  cartItems,
  isModalOpened,
  isLoading,
  dataQaId,
  renderFooterActions,
  title,
  subtitle,
  deleteCartItem,
  className,
}) => {
  /**
   * @param {number} id
   * @param {string} redirectUrl
   */
  const onSelectAnotherRoom = (id, redirectUrl) => {
    const callback = () => (window.location.href = redirectUrl);

    deleteCartItem(id, callback);
  };

  /**
   * @param {object} item
   * @return {JSX.Element}
   */
  const renderCartItem = (item) => {
    const data = {};

    if (item.product_type === PRODUCT_TYPES.group) {
      data.id = item.id;
      data.image = item.image || item.products_info[0].image;
      data.booking = {
        booking_start_date: item.group_info.start_date,
        booking_end_date: item.group_info.end_date,
      };
      data.title = item.title;
      data.nights = item.group_info.nights;
      data.isNotAvailable = item.isNotAvailable;
    } else {
      data.id = item.id;
      data.image =
        item.product_type === PRODUCT_TYPES.listing
          ? { link: item.hotelbeds_rate_info.image.path }
          : item.products_info[0].image;
      data.booking = {
        booking_start_date: item.products_info[0].booking?.booking_start_date,
        booking_end_date: item.products_info[0].booking?.booking_end_date,
      };
      data.title = item.products_info[0].package_title || item.products_info[0].title;
      data.nights = item.products_info[0].nights;
      data.isNotAvailable = item.isNotAvailable;
    }

    return (
      <div
        className={`restore-abandoned-cart__item d-flex${
          data.isNotAvailable ? ' restore-abandoned-cart__item--not-avail' : ''
        }`}
        data-qa-id="qa_abandoned_cart_item"
      >
        {data.image ? (
          <Image
            key={data.id}
            data={data.image}
            config={{
              size: IMAGE_SIZES.SMALL,
              squared: true,
            }}
            background
            className=" restore-abandoned-cart__item-image restore-abandoned-cart__item-image--background"
            data-qa-id="cart_item_image"
          />
        ) : (
          <div className="restore-abandoned-cart__item-image restore-abandoned-cart__item-image--empty" />
        )}

        <div className="restore-abandoned-cart__item-info">
          <div className="restore-abandoned-cart__item-info-top">
            <div className="d-flex justify-content-between mb-5">
              <p className="mb-0 pr-15" data-qa-id="cart_item_title">
                <strong>{data.title}</strong>
              </p>

              <p className="mb-0 price" data-qa-id="cart_item_price">
                <strong>
                  <Price {...item.price} divLess />
                </strong>
              </p>
            </div>

            {data.nights && (
              <p className="mb-5" data-qa-id="cart_item_nights">
                {data.nights} {t(pluralize('night', data.nights))}
              </p>
            )}

            {data.booking.booking_start_date && data.booking.booking_end_date && (
              <p className="mb-0" data-qa-id="cart_item_booking_dates">
                <span data-qa-id="cart_item_start_date">
                  {toUTCDate(data.booking.booking_start_date)}
                </span>{' '}
                -{' '}
                <span data-qa-id="cart_item_end_date">
                  {toUTCDate(data.booking.booking_end_date)}
                </span>
              </p>
            )}
          </div>

          {data.isNotAvailable && (
            <div className="restore-abandoned-cart__item-info-bottom">
              {item.redirectUrl && (
                <button
                  type="button"
                  onClick={() => onSelectAnotherRoom(data.id, item.redirectUrl)}
                  className="link-red"
                >
                  Select Another Room
                </button>
              )}

              <p className="mb-0" data-qa-id="cart_item_unavailable">
                unavailable
              </p>

              <button
                type="button"
                className="restore-abandoned-cart__delete-btn"
                onClick={() => deleteCartItem(data.id)}
                data-qa-id="qa_delete_item"
              >
                delete
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <DefaultModal
      isOpen={isModalOpened}
      size="lg"
      data={{
        'qa-id': dataQaId,
      }}
      className={className}
      title={title}
      subHeading={subtitle}
      footerActions={renderFooterActions}
    >
      {isLoading && <OverlaySpinner isAbsolute />}

      <div className="restore-abandoned-cart">
        {cartItems.map((item) => (
          <Fragment key={item.id}>{renderCartItem(item)}</Fragment>
        ))}
      </div>
    </DefaultModal>
  );
};

CartItemsModal.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isModalOpened: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataQaId: PropTypes.string,
  renderFooterActions: PropTypes.func,
  deleteCartItem: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

CartItemsModal.defaultProps = {
  isModalOpened: false,
  isLoading: false,
  dataQaId: 'qa_popup_restore_abandoned_cart',
  renderFooterActions: () => {},
  deleteCartItem: () => {},
  subtitle: '',
  className: '',
};

export default ErrorBoundaryDecorator()(CartItemsModal);
