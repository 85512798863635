import React from 'react';

import { getLinkToGo } from '@jsv3/utils/urlUtils';

const Join = () => (
  <a href={getLinkToGo()} className="navlist__btn navlist__btn--black" data-qa-id="join-us-button">
    Join us
  </a>
);

export default Join;
