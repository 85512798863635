import React from 'react';
import { connect } from 'react-redux';

import ActionButton from '@jsv2/config/Header/ActionButton';

/**
 * Render fixed CTA button.
 *
 * @param {boolean} show
 *
 * @returns <FixedBuyButton show={show} />
 */
const FixedBuyButton = ({ show }) => (show ? <ActionButton /> : null);

const mapStateToProps = (state) => ({
  show: state.fixedBuyButton.show,
});

export default connect(mapStateToProps)(FixedBuyButton);
