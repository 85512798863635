import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Modal from '@jsv3/components/organisms/Modal';
import LayoverTitle from '@jsv3/components/atoms/LayoverTitle';

/**
 * @param {boolean} isOpen
 * @param {string} title
 * @param {string} subHeading
 * @param {function} footerActions
 * @param {node} children
 * @param {object} rest
 * @return {JSX.Element}
 * @constructor
 */
const DefaultModal = ({ isOpen, title, subHeading, footerActions, children, ...rest }) => (
  <Modal isOpen={isOpen} {...rest}>
    <div className="default-modal">
      <div className="default-modal__header">
        {title && <LayoverTitle dataQaId="modal-title">{title}</LayoverTitle>}

        {subHeading && (
          <h3 className="text-center" data-qa-id="modal_sub-title">
            {subHeading}
          </h3>
        )}
      </div>

      {children && children}

      {footerActions && <div className="default-modal__footer">{footerActions()}</div>}
    </div>
  </Modal>
);

DefaultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subHeading: PropTypes.string,
  footerActions: PropTypes.func,
  children: PropTypes.node,
};

DefaultModal.defaultProps = {
  title: '',
  subHeading: '',
  children: null,
  footerActions: () => null,
};

export default ErrorBoundaryDecorator()(DefaultModal);
