import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

import Spinner from '@components/Spinner';
import Image from '@jsv2/components/Image';
import apiClient from '@js/apiClient';
import { alert } from '@utils/Dialogs';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const FILE_TYPE_KEYS = {
  FILE: 'file',
  IMAGE: 'image',
};

const MAX_MESSAGE_ATTACHMENTS_NUMBER = 5;

class ChatFileUploader extends Component {
  state = {
    isSubmitting: false,
  };

  fileInput = null;
  allowedFileTypes = {
    [FILE_TYPE_KEYS.IMAGE]: ['image/jpeg', 'image/png'],
    [FILE_TYPE_KEYS.FILE]: [
      'application/rtf',
      'application/msword',
      'application/docx',
      'application/pdf',
      'text/plain',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.docx',
      '.pdf',
      '.doc',
      '.xls',
      '.xlsx',
      '.csv',
      '.txt',
      '.rtf',
      '.zip',
    ],
  };
  maxFileSize = 2; // MB

  getFileTypeByMime(type) {
    return Object.keys(this.allowedFileTypes).reduce((result, key) => {
      const allowed = this.allowedFileTypes[key];
      if (allowed && allowed.includes(type)) {
        return key;
      }

      return result;
    }, null);
  }

  getConversationFileTypeByKey(key) {
    return `conversation_message_attachment_${key}`;
  }

  /**
   * @param file
   */
  uploadFile = (file) => {
    const formData = new FormData();

    const type = this.getFileTypeByMime(file.type);

    const data = {
      file,
      type,
      file_type: this.getConversationFileTypeByKey(type),
      entity_type: 'ConversationMessage',
      entity_id: 0,
    };

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    this.props.setIsFileUploading(true);
    this.setState({ isSubmitting: true });

    return apiClient({
      url: '/file/upload',
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  /**
   * @param {Object} e
   */
  uploadFiles = (e) => {
    const fileInput = e.target;
    const { files } = fileInput;
    const filesArray = Array.from(files).reverse();

    if (!this.validateFiles(filesArray)) {
      this.resetFileInput(fileInput);

      return;
    }

    Promise.all(filesArray.map((file) => this.uploadFile(file)))
      .then((fileData) => {
        const fileEntities = fileData.map((response) => response.data);
        this.props.setValue(fileEntities);
      })
      .catch(() => {
        alert(t('Please, upload a valid image file.'));
      })
      .finally(() => {
        this.props.setIsFileUploading(false);
        this.setState({ isSubmitting: false });
        this.resetFileInput(fileInput);
      });
  };

  resetFileInput = (fileInput) => {
    fileInput.value = '';
  };

  /**
   * Validate selected file.
   *
   * @return {boolean} True if ok.
   */
  validateFiles = (files) => {
    if (!files.length) {
      return false;
    }

    let allErrors = [];

    if (files.length > MAX_MESSAGE_ATTACHMENTS_NUMBER) {
      allErrors.push(
        t("The number of attachments shouldn't exceed :max_file_number files", {
          max_file_number: MAX_MESSAGE_ATTACHMENTS_NUMBER,
        }),
      );
    }

    allErrors = allErrors.concat(
      files.reduce((errors, file) => {
        if (file.size / 1000 / 1000 > this.maxFileSize) {
          errors.push(
            t('Selected file is too large. Max file size is :max_file_size MB.', {
              max_file_size: this.maxFileSize,
            }),
          );
        }

        const isAllowedFileType = Object.values(this.allowedFileTypes).some((element) =>
          element.includes(file.type),
        );

        if (!isAllowedFileType) {
          errors.push(t('Selected file type is not allowed. Please select a valid image file.'));
        }

        return errors;
      }, []),
    );

    if (allErrors.length) {
      alert(allErrors.join('<br />'));

      return false;
    }

    return true;
  };

  getAcceptFiles = () =>
    Object.values(this.allowedFileTypes)
      .reduce((acceptedFiles, item) => acceptedFiles.concat(item), [])
      .join(',');

  removeAttachment = (file) => this.props.removeAttachment(file);

  hasAttachment = () => !!this.props.attachment.length;

  render() {
    const { isSubmitting } = this.state;
    return (
      <>
        {this.hasAttachment() && !isSubmitting && (
          <div className="attachment-preview-wrapper">
            <div className="btn-chat btn-chat--attachment" />

            <div className="attachment-preview test">
              {this.props.attachment.map((attachment) => (
                <Image
                  className="attachment-preview-item"
                  key={attachment.id}
                  data={attachment}
                  background
                >
                  <button
                    type="button"
                    className="btn-chat btn-chat--remove"
                    onClick={() => this.removeAttachment(attachment)}
                  />
                </Image>
              ))}
            </div>
          </div>
        )}

        {!this.hasAttachment() && !isSubmitting && (
          <div className="form-field">
            <label htmlFor="fileInput" className="btn-chat attach-image">
              <input
                type="file"
                accept={this.getAcceptFiles()}
                name="fileInput"
                id="fileInput"
                multiple
                ref={(node) => (this.fileInput = node)}
                onChange={this.uploadFiles}
              />
            </label>
            <ErrorMessage name="fileInput" component="div" className="error" />
          </div>
        )}

        {isSubmitting && <Spinner color="white" />}
      </>
    );
  }
}

ChatFileUploader.propTypes = {
  attachment: PropTypes.arrayOf(PropTypes.object),
  removeAttachment: PropTypes.func.isRequired,
};

ChatFileUploader.defaultProps = {
  attachment: [],
};

export default ErrorBoundaryDecorator()(ChatFileUploader);
