import React from 'react';

const PARAMS = {
  title: 'Bad Request',
  message: '',
};

const BadRequest = () => (
  <>
    <div className="message_title">
      {PARAMS.title}
    </div>

    <div className="title">
      {PARAMS.message}
    </div>
  </>
);

export default BadRequest;
