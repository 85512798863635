import React from 'react';
import PropTypes from 'prop-types';

const ContactUs = ({ phoneNumber }) => (
  <div className="navlist__item contact-block">
    <a className="navlist__btn" href={'tel:' + phoneNumber.replace(/\s/g, '')}>
      <img src="/images/icons/phone-black.svg" alt="" />

      <span>{' '}{phoneNumber}</span>
    </a>
  </div>
);

ContactUs.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default ContactUs;
