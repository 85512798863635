import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const Cart = ({ itemsInCart }) => (
  <a
    href="/checkout"
    className="navlist__btn navlist__btn--black navlist__btn--cart"
    data-qa-id="cart-button"
  >
    <span>
      Checkout
      {/* <span> */}
      {/*  {itemsInCart} */}
      {/* </span> */}
    </span>
  </a>
);

Cart.propTypes = {
  itemsInCart: PropTypes.number.isRequired,
};

export default Cart;
