import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import TopLinkItem from '@jsv2/components/Header/components/TopLinkItem';
import { getBackLinkToGo } from '@jsv3/utils/urlUtils';

/**
 * Render a link button "VIP+"
 *
 * @return {*}
 *
 * @constructor
 */
const VIPPlus = ({ shouldGoBack }) => (
  <TopLinkItem
    id="vip-plan"
    title="VIP+"
    href={'/?choosePlanSection=1' + (shouldGoBack ? `&go_back_url=${getBackLinkToGo()}` : '')}
  />
);

VIPPlus.propTypes = {
  shouldGoBack: PropTypes.bool,
};

VIPPlus.defaultProps = {
  shouldGoBack: false,
};

export default ErrorBoundaryDecorator()(VIPPlus);
