import React, { forwardRef } from 'react';

import { inputPropTypes, inputDefaultProps } from '@jsv3/propTypes/inputProps';
import processEmptyProp from '@jsv3/utils/helpers/processEmptyPropHelper';

/**
 * Atom <input> (forwardRef)
 */
const InputField = forwardRef(
  ({ id, value, placeholder, dataQaId, isInputUncontrolled, ...props }, ref) => (
    <input
      ref={ref}
      name={id}
      id={id}
      {...props}
      {...processEmptyProp('value', isInputUncontrolled ? null : value)}
      {...processEmptyProp('placeholder', placeholder)}
      {...processEmptyProp('data-qa-id', dataQaId)}
    />
  ),
);

InputField.propTypes = inputPropTypes;
InputField.defaultProps = inputDefaultProps;

export default InputField;
