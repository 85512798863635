import React from 'react';
import PropTypes from 'prop-types';

import Attachment from '@components/Chat/Attachment';
import MessageContent from '@components/Chat/MessageContent';
import { toLocalDate } from '@utils/dateUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Message = ({
  created_at: createdAt,
  is_customer_author: isCustomerAuthor,
  source_type: sourceType,
  message,
  files,
  onPreviewClick,
  ...rest
}) => {
  const msgDate = createdAt && toLocalDate(createdAt, '');

  const authorClass = isCustomerAuthor ? 'user' : 'employee';

  return (
    <div className={`msg-row ${authorClass}`}>
      {message && (
        <div className={`msg-top msg-top--${sourceType}`} data-qa-id="message_text">
          <MessageContent
            message={message}
            sourceType={sourceType}
            onEmailClick={onPreviewClick}
            {...rest}
          />
        </div>
      )}

      {files && files.length > 0 && (
        <div className="msg-files">
          {files.map((file) => (
            <Attachment key={file.id} file={file} onImageClick={onPreviewClick} />
          ))}
        </div>
      )}

      <div className="msg-details">
        <div className="msg-date" data-qa-id="message_date">{msgDate || 'sending...'}</div>
        <div className={`msg-source msg-${sourceType}`} />
      </div>
    </div>
  );
};

Message.propTypes = {
  onPreviewClick: PropTypes.func,
  is_customer_author: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  message: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  source_type: PropTypes.string.isRequired,
};

Message.defaultProps = {
  onPreviewClick: () => {},
  created_at: null,
};

export default ErrorBoundaryDecorator()(Message);
