import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import {
  getUserConversationApi,
  getUserHistoryApi,
} from '@jsv2/utils/CommunicationUtils/APIRequests';
import { communicationsPanelConfigStylist } from '@components/sections/stylist-page/CommunicationsSection/communicationPanelConfig';
import { setCommunicationDataAction } from '@js/ducks/communications';
import WarningMessage from './WarningMessage';
import CommunicationWrapper from './CommunicationWrapper';
import CommunicationsPanel from './CommunicationsPanel';
import { CHAT_CHANNEL_TITLE } from './communicationChannelsConfig';

const CommunicationsSection = React.forwardRef(
  (
    {
      heading,
      userStylist,
      setCommunicationData,
      children,
      defaultMessage,
      resetDefaultMessage,
      wrapperClassNames,
      ...rest
    },
    ref,
  ) => {
    const screenTypeContext = useContext(ScreenTypeContext);
    const panelRef = useRef(null);
    const [activeTab, toggleTab] = useState(CHAT_CHANNEL_TITLE);
    const [isModalOpen, setModalOpen] = useState(false);
    const [hideMobileNavigation, setHideMobileNavigation] = useState(false);

    const channels = useMemo(() => communicationsPanelConfigStylist(userStylist), [userStylist]);

    /**
     * Toggle mobile chat panel
     */
    const toggleMobileChatPanel = () => {
      const { clientHeight, offsetTop } = panelRef.current;
      const { pageYOffset, innerHeight } = window;
      const topLine = pageYOffset + innerHeight;
      const containerBottomOffset = clientHeight / 2 + offsetTop;

      if (topLine < offsetTop || pageYOffset > containerBottomOffset) {
        setHideMobileNavigation(true);
      }

      if (topLine > offsetTop && pageYOffset < containerBottomOffset) {
        setHideMobileNavigation(false);
      }
    };

    const getUserConversation = () =>
      getUserConversationApi()
        .then(({ data }) => {
          if (data.id) {
            setCommunicationData('messages', data.messages);
          }
        })
        .catch((err) => Logger.error(err));

    const getUserHistory = () =>
      getUserHistoryApi()
        .then(({ data: { messages } }) => {
          setCommunicationData('history', messages);
        })
        .catch((err) => Logger.error(err));

    useEffect(() => {
      if (isMobile(screenTypeContext)) {
        toggleMobileChatPanel();
        window.addEventListener('scroll', toggleMobileChatPanel, { passive: true });
      }

      return () => {
        window.removeEventListener('scroll', toggleMobileChatPanel);
      };
    }, [screenTypeContext]);

    /**
     * Handles click on communication channels.
     *
     * @param channel
     */
    const handleClickChannel = (channel) => {
      if (isMobile(screenTypeContext)) {
        const headerHeight = document.getElementById('main-header').clientHeight;
        const scrollPosition = panelRef.current.offsetTop - headerHeight * 3;

        window.scrollTo({ behavior: 'smooth', top: scrollPosition });
      }

      if (defaultMessage) {
        resetDefaultMessage();
      }

      if (channel.enabled) {
        toggleTab(channel.name);
      } else {
        setModalOpen(true);
      }
    };

    const handleOpenChat = () => {
      toggleTab('chat');
    };

    // After updating the stylist data, open the chat tab.
    useEffect(() => {
      handleOpenChat();
    }, [userStylist.email]);

    // expose this function to outside
    useImperativeHandle(ref, () => ({
      handleOpenChat,
    }));

    return (
      <div className={`communication-section ${wrapperClassNames}`} ref={ref}>
        <div className="section-container">
          {!!heading && (
            <h3 className="section__heading section-title section-title--underline">{heading}</h3>
          )}

          <CommunicationsPanel
            activeTab={activeTab}
            channels={channels}
            handleClickChannel={handleClickChannel}
            hidePanel={hideMobileNavigation}
          />

          <div className="communication-container">
            <CommunicationWrapper
              ref={panelRef}
              activeTab={activeTab}
              defaultMessage={defaultMessage}
              getUserConversation={getUserConversation}
              getHistory={getUserHistory}
              {...rest}
            />
            {children}
          </div>
        </div>

        <WarningMessage isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
      </div>
    );
  },
);

CommunicationsSection.displayName = 'CommunicationsSection';

CommunicationsSection.propTypes = {
  heading: PropTypes.string,
  videoChatTo: PropTypes.func,
  isStylistOnline: PropTypes.bool,
  defaultMessage: PropTypes.string,
  resetDefaultMessage: PropTypes.func,
  context_type: PropTypes.string,
  entity_id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  entity_type: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  wrapperClassNames: PropTypes.string,
};

CommunicationsSection.defaultProps = {
  heading: '',
  videoChatTo: () => {},
  isStylistOnline: false,
  defaultMessage: '',
  resetDefaultMessage: () => {},
  context_type: null,
  entity_id: null,
  entity_type: null,
  wrapperClassNames: '',
};

const mapStateToProps = ({ communications: { userStylist } }) => ({ userStylist });

const mapDispatchToProps = {
  setCommunicationData: setCommunicationDataAction,
};

export default ErrorBoundaryDecorator()(
  connect(mapStateToProps, mapDispatchToProps)(CommunicationsSection),
);
