import React from 'react';
import PropTypes from 'prop-types';

const PhoneButton = ({ phoneNumber }) => (
  <a className="phone-action-btn" href={'tel:' + phoneNumber.replace(/\s/g, '')}>
    <img
      src="/images/icons/phone-black.svg"
      className="phone-icon"
      alt=""
    />

    <div className="phone-number">{' '}{phoneNumber}</div>
  </a>
);

PhoneButton.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default PhoneButton;
