import React from 'react';
import PropTypes from 'prop-types';

import TopLinkItem from '@jsv2/components/Header/components/TopLinkItem';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const BestOfTheRest = ({ url }) => (
  <TopLinkItem id="best-rest-button" title="VIP Exclusives" href={url} />
);

BestOfTheRest.propTypes = {
  url: PropTypes.string,
};

BestOfTheRest.defaultProps = {
  url: defaultHomePageUrl,
};

export default ErrorBoundaryDecorator()(BestOfTheRest);
