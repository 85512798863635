import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import Linkify from 'react-linkify';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const MessageContent = ({ message, messagePreview, sourceType, onEmailClick, options }) => {
  switch (sourceType) {
    case 'email':
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={() =>
            onEmailClick({
              message,
              previewType: 'email',
              subject: options.email_conversation_subject,
            })
          }
          className="html-content msg-preview"
        >
          <div className="msg-email-subject">
            <span>Email subject: </span>
            {options.email_conversation_subject}
          </div>

          {messagePreview}
        </div>
      );
    default:
      return (
        <div className="html-content">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {Parser(message)}
          </Linkify>
        </div>
      );
  }
};

MessageContent.propTypes = {
  message: PropTypes.string.isRequired,
  sourceType: PropTypes.string.isRequired,
  onEmailClick: PropTypes.func,
  messagePreview: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

MessageContent.defaultProps = {
  onEmailClick: () => {},
  messagePreview: '',
  options: null,
};

export default ErrorBoundaryDecorator()(MessageContent);
