import moment from 'moment';

/**
 * Parse and convert a date value into specified format
 *
 * @param {string|Date} value       Date value
 * @param {string}      dateFormat  Output format of the date
 *
 * @return {string}
 */
export function toLocalDate(value, dateFormat) {
  const mDate = moment(value);

  const format =
    dateFormat || mDate.isSame(new Date(), 'day') ? 'hh:mm:ss A' : 'M/D/YYYY hh:mm:ss A';

  return mDate.subtract(mDate.toDate().getTimezoneOffset(), 'minutes').format(format);
}
