/**
 * The code below installs a service worker and asks user to subscribe to push notifications.
 * Push notifications are handled in the service worker file:
 * applications/front/public/service-worker.js
 */
import apiClient from '@js/apiClient';

export default {
  init() {
    if (!navigator || typeof navigator !== 'object') {
      // navigator isn't defined
      return;
    }

    if (!('serviceWorker' in navigator) || typeof navigator.serviceWorker !== 'object') {
      // service worker isn't supported
      return;
    }

    if (!('PushManager' in window)) {
      // push isn't supported
      return;
    }

    // register the service worker
    navigator.serviceWorker.register('/service-worker.js').then(() => {
      this.initPush();
    });
  },

  initPush() {
    if (!navigator.serviceWorker.ready) {
      return;
    }

    new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then((permissionResult) => {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission to show notifications.");
      }

      this.subscribeUser();
    });
  },

  subscribeUser() {
    const { vapidPubKey } = window.__SERVER_DATA__;

    if (!vapidPubKey) {
      throw new Error('Unable to initialize push notifications. Vapid Pub Key is missing.');
    }

    navigator.serviceWorker.ready
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(vapidPubKey),
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((pushSubscription) => {
        this.storePushSubscription(pushSubscription);
      });
  },

  storePushSubscription(pushSubscription) {
    apiClient.post('/push-subscriptions/store', pushSubscription);
  },

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  },
};
