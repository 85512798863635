import React, { useContext } from 'react';

import ScreenTypeContext, { isDesktop } from '@js/context/ScreenTypeContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import usePageVisibility from '@jsv3/hooks/usePageVisibility';
import Cart from '../../components/Header/Items/Action/Cart';
import Join from '../../components/Header/Items/Action/Join';
import Reserve from '../../components/Header/Items/Action/Reserve';
import CollectionLink from '../../components/Header/Items/CollectionLink';

import { isAnonymous } from '../../utils/UserUtils/userStatuses';

// TODO: pass from context
const { userRoles } = window;
const { offer } = window.__SERVER_DATA__;

/**
 * Returns CTA button depending on user status and cart items.
 *
 * @returns <ActionButton />
 */
const ActionButton = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const { previewPage, signInPage, showCartButton, itemsInCart } = usePageVisibility();

  if (showCartButton() && !signInPage()) {
    return <Cart key="cart" itemsInCart={itemsInCart} />;
  }

  if (offer && !previewPage()) {
    if (!isAnonymous(userRoles) && isDesktop(screenTypeContext)) {
      return null;
    }

    return <Reserve key="reserve" />;
  }

  if (isAnonymous(userRoles) && isDesktop(screenTypeContext)) {
    return <Join key="join" />;
  }

  if (!isDesktop(screenTypeContext)) {
    return <CollectionLink key="collection" />;
  }

  return null;
};

export default ErrorBoundaryDecorator()(ActionButton);
