import React, { useContext } from 'react';

import ScreenTypeContext, { isDesktop, isMobile } from '@js/context/ScreenTypeContext';
import PlanATrip from '@jsv2/components/Header/Items/PlanATrip';
import VIPPlus from '@jsv2/components/Header/Items/VIPPlus';
import AboutUs from '@jsv2/components/Header/Items/AboutUs';
// import WhatWeDo from '@jsv2/components/Header/Items/WhatWeDo';
import Login from '@jsv2/components/Header/Items/Login';
import { HOME_PAGE_TOKEN_URL } from '@jsv2/Enums/UrlEnums';
import BestOfTheRest from '../../components/Header/Items/BestOfTheRest';
// import LuxuryListings from '../../components/Header/Items/LuxuryListings';
import TravelFinds from '../../components/Header/Items/TravelFinds';
import BucketList from '../../components/Header/Items/BucketList';
// import Stylist from '../../components/Header/Items/Stylist';
// import StylistLocked from '../../components/Header/Items/StylistLocked';
import Account from '../../components/Header/Items/Account';
import ContactUs from '../../components/Header/Items/ContactUs';
import CollectionLink from '../../components/Header/Items/CollectionLink';
import UpgradePlan from '../../components/Header/Items/Profile/UpgradePlan';
import ProfileMenu from './ProfileMenu';
import {
  isAnonymous,
  isEmailOnly,
  isFreeMember,
  isIncompleteSignUp,
  isPaidMember,
  isPreviouslyPaidMember,
  isWaitListMember,
} from '../../utils/UserUtils/userStatuses';

// TODO: pass from context
const { userRoles } = window;
const { offer } = window.__SERVER_DATA__;

const phoneNumber = window.brand.phone_number_contact;

/**
 * List of menu items available for user w/ role free-member
 *
 * @returns <FreeMemberItems />
 */
const FreeMemberItems = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isDesktop(screenTypeContext) && offer && phoneNumber && (
        <ContactUs phoneNumber={phoneNumber} />
      )}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      {isMobile(screenTypeContext) && <UpgradePlan />}
      <BestOfTheRest />
      <PlanATrip />
      <VIPPlus />
      <TravelFinds />
      {/* <LuxuryListings /> */}
      <BucketList />

      <Account>{ProfileMenu}</Account>
    </>
  );
};

/**
 * List of menu items available for user w/ role paid-member
 *
 * @returns <PaidMemberItems />
 */
const PaidMemberItems = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isDesktop(screenTypeContext) && offer && phoneNumber && (
        <ContactUs phoneNumber={phoneNumber} />
      )}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      <BestOfTheRest />
      <PlanATrip />
      <VIPPlus />
      <TravelFinds />
      {/* <Stylist /> */}
      {/* <LuxuryListings /> */}
      <BucketList />

      <Account>{ProfileMenu}</Account>
    </>
  );
};

/**
 * List of menu items available for not authenticated user
 *
 * @returns <NotAuthenticated />
 */
const NotAuthenticated = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isDesktop(screenTypeContext) && phoneNumber && <ContactUs phoneNumber={phoneNumber} />}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      <BestOfTheRest url={HOME_PAGE_TOKEN_URL} />
      {/* <LuxuryListings /> */}
      <PlanATrip shouldGoBack />
      <VIPPlus shouldGoBack />
      <AboutUs shouldGoBack />
      {/* <WhatWeDo shouldGoBack /> */}
    </>
  );
};

/**
 * List of default menu items.
 *
 * @returns <Default />
 */
const Default = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isMobile(screenTypeContext) && <Login />}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      <BestOfTheRest url={HOME_PAGE_TOKEN_URL} />
      {/* <LuxuryListings /> */}
      <PlanATrip />
      <VIPPlus />
      <AboutUs />
      {/* <WhatWeDo /> */}
    </>
  );
};

/**
 * List of menu items available for incomplete sign up
 *
 * @returns <IncompleteMemberItems />
 */
const IncompleteMemberItems = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isDesktop(screenTypeContext) && offer && phoneNumber && (
        <ContactUs phoneNumber={phoneNumber} />
      )}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      <Account>{ProfileMenu}</Account>
    </>
  );
};

/**
 * List of menu items available for user w/ role waitlist-member
 *
 * @returns <WaitListMemberItems />
 */
const WaitListMemberItems = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isDesktop(screenTypeContext) && offer && phoneNumber && (
        <ContactUs phoneNumber={phoneNumber} />
      )}
      {isDesktop(screenTypeContext) && <CollectionLink />}
      <BestOfTheRest />
      <PlanATrip />
      <VIPPlus />
      <TravelFinds />
      {/* <StylistLocked /> */}
      {/* <LuxuryListings /> */}
      <BucketList />

      <Account>{ProfileMenu}</Account>
    </>
  );
};

/**
 * Returns specific set of menu item depending on current user role.
 *
 * @returns {JSX.Element[]}
 */
const menuItems = () => {
  if (isPaidMember(userRoles)) {
    return <PaidMemberItems />;
  }

  if (isWaitListMember(userRoles)) {
    return <WaitListMemberItems />;
  }

  if (isFreeMember(userRoles) || isEmailOnly(userRoles) || isPreviouslyPaidMember(userRoles)) {
    return <FreeMemberItems />;
  }

  if (isAnonymous(userRoles) && offer) {
    return <NotAuthenticated />;
  }

  if (isIncompleteSignUp(userRoles)) {
    return <IncompleteMemberItems />;
  }

  return <Default />;
};

export default menuItems();
