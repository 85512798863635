export const communicationsPanelConfigStylist = () => [
  {
    name: 'chat',
    title: 'Chat',
    mobileTitle: 'Chat',
    enabled: true,
  },
  // {
  //   name: 'phone',
  //   title: 'Call',
  //   mobileTitle: 'Call',
  //   enabled: true,
  // },
  // {
  //   name: 'whatsapp',
  //   title: 'Whatsapp',
  //   mobileTitle: 'Whatsapp',
  //   enabled: !!whatsappPhone,
  // },
  {
    name: 'video',
    title: 'Video Chat',
    mobileTitle: 'Video',
    enabled: true,
  },
  {
    name: 'email',
    title: 'Email',
    mobileTitle: 'Email',
    enabled: true,
  },
  {
    name: 'history',
    title: 'History',
    mobileTitle: 'History',
    enabled: true,
  },
];
