import pluralize from 'pluralize';

import { HIDDEN_ATTR_POLYFILL_MAP } from '@js/constants';

const HIDDEN_ATTR_TYPE = Object.keys(HIDDEN_ATTR_POLYFILL_MAP).find(
  (attr) => typeof document[attr] !== 'undefined',
);

const FAVICON_SIZE = {
  LARGE: 180,
  MIDDLE: 48,
  SMALL: 32,
};

class TabNotificationsListener {
  defaultPageTitle = '';
  isHiddenPage = false;
  user = null;
  defaultFaviconsData = [];
  titleIntervalId = null;

  newFavicons = [];

  constructor() {
    this.defaultPageTitle = document.title;
    this.isHiddenPage = document[HIDDEN_ATTR_TYPE];
    this.user = window.user;
  }

  run() {
    this.addTabToStorage();
    this.getDefaultFaviconsData();

    // visibilitychange event listener
    this.onVisibilityChange((isVisible) => {
      this.isHiddenPage = document.hidden;

      if (isVisible) {
        this.addTabToStorage();

        localStorage.setItem('numberOfUnreadMessages', 0);

        this.removeBlinkingElements();
      } else {
        this.removeTabFromStorage();
      }
    });

    // localStorage event listener
    window.addEventListener('storage', this.onStorageChange);

    window.addEventListener('beforeunload', () => {
      if (!this.isHiddenPage) {
        this.removeTabFromStorage();
      }

      this.clearIntervals();
    });
  }

  update(messages = []) {
    const numberOfOpenedTabs = localStorage.getItem('numberOfOpenedTabs');

    if (this.isHiddenPage && messages.length > 0 && +numberOfOpenedTabs === 0) {
      this.countingIncomingMessages(messages);
    }
  }

  /**
   * Get default favicons.
   */
  getDefaultFaviconsData = () => {
    const elements = document.getElementsByClassName('favicon-icon');

    for (const item of elements) {
      this.defaultFaviconsData.push({
        size: item.getAttribute('data-size'),
        href: item.href,
      });
    }
  };

  /**
   * Add tab to storage.
   */
  addTabToStorage = () => {
    const numberOfTabs = localStorage.getItem('numberOfOpenedTabs');

    if (numberOfTabs) {
      if (!this.isHiddenPage) {
        localStorage.setItem('numberOfOpenedTabs', +numberOfTabs + 1);
      }
    } else {
      localStorage.setItem('numberOfOpenedTabs', 0);
    }
  };

  /**
   * Remove tab from storage.
   */
  removeTabFromStorage = () => {
    const numberOfTabs = localStorage.getItem('numberOfOpenedTabs');

    if (+numberOfTabs) {
      localStorage.setItem('numberOfOpenedTabs', numberOfTabs - 1);
    }
  };

  /**
   * Storage listener.
   */
  onStorageChange = () => {
    const numberOfMessages = localStorage.getItem('numberOfUnreadMessages');

    if (+numberOfMessages === 0) this.removeBlinkingElements();
  };

  /**
   * Tab visibility listener.
   */
  onVisibilityChange = (handleVisibilityChange) => {
    if (typeof document.addEventListener !== 'undefined' && HIDDEN_ATTR_TYPE !== undefined) {
      document.addEventListener(
        HIDDEN_ATTR_POLYFILL_MAP[HIDDEN_ATTR_TYPE],
        () => handleVisibilityChange(!document[HIDDEN_ATTR_TYPE]),
        false,
      );
    }
  };

  /**
   * Counting the number of incoming messages.
   *
   * @param messages
   */
  countingIncomingMessages = (messages) => {
    const unreadMessages = messages
      .filter((message) => !message.opened_at)
      .filter((message) => !message.is_customer_author);
    const notificationCounter = unreadMessages.length;

    if (notificationCounter > 0) {
      localStorage.setItem('numberOfUnreadMessages', notificationCounter);

      // clear favicons array
      this.newFavicons.length = 0;

      for (const [key, item] of this.defaultFaviconsData.entries()) {
        this.createFaviconBadge(notificationCounter, item, key);
      }

      this.clearIntervals();
      this.createBlinkingElements(notificationCounter);
    }
  };

  /**
   * Create a blinking page title with the number of incoming messages.
   *
   * @param notificationCounter
   */
  createBlinkingElements = (notificationCounter) => {
    const favicons = document.getElementsByClassName('favicon-icon');

    this.titleIntervalId = setInterval(() => {
      if (document.title === this.defaultPageTitle) {
        document.title = `${notificationCounter} ${pluralize('notification', notificationCounter)}`;

        [...favicons].forEach((item, key) => {
          favicons[key].href = this.newFavicons[key];
        });
      } else {
        document.title = this.defaultPageTitle;

        [...favicons].forEach((item, key) => {
          favicons[key].href = this.defaultFaviconsData[key].href;
        });
      }
    }, 500);
  };

  /**
   * Set default page title and favicons.
   */
  removeBlinkingElements = () => {
    const favicons = document.getElementsByClassName('favicon-icon');

    document.title = this.defaultPageTitle;

    for (const [key, item] of [...favicons].entries()) {
      item.href = this.defaultFaviconsData[key].href;
    }

    this.clearIntervals();
  };

  getFontSizeForBadge = (size, numberOfMessages) => {
    switch (size) {
      case FAVICON_SIZE.LARGE:
        return '100px';
      case FAVICON_SIZE.MIDDLE:
        return '30px';
      case FAVICON_SIZE.SMALL:
        return '20px';
      default:
        if (numberOfMessages > 9) {
          return '10px';
        }

        return '12px';
    }
  };

  /**
   * Create a favicon message count badge.
   *
   * @param numberOfMessages
   * @param item
   */
  createFaviconBadge = (numberOfMessages, item) => {
    const faviconSize = +item.size;
    const fontSize = this.getFontSizeForBadge(faviconSize);
    const badgeTitle = numberOfMessages > 9 ? '9+' : numberOfMessages;

    const canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;

    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    img.src = item.href;

    img.onload = () => {
      // Draw Notification Circle
      context.beginPath();
      context.arc(canvas.width - faviconSize / 2, faviconSize / 2, faviconSize / 2, 0, 2 * Math.PI);
      context.fillStyle = '#FF0000';
      context.fill();

      // Draw Notification Number
      context.font = `${fontSize} "Helvetica", sans-serif`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillStyle = '#FFFFFF';
      context.fillText(badgeTitle, canvas.width - faviconSize / 2, faviconSize / 2);

      const newUrl = canvas.toDataURL('image/png');
      this.newFavicons.push(newUrl);
    };
  };

  clearIntervals = () => {
    if (this.titleIntervalId) {
      clearInterval(this.titleIntervalId);
    }
  };
}

export default TabNotificationsListener;
