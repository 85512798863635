import apiClient from '@js/apiClient';
import { withResolutionsFilter } from '@jsv2/utils/imageUtils';

const resolutionsConfig = withResolutionsFilter(['w600']); // USE_RESOLUTION: w350

/**
 * Mark messages as read.
 *
 * @returns {*}
 */
export function setMessagesShowedApi(messageIds) {
  return apiClient.put('/conversation/set-showed', {
    ids: [...messageIds],
  });
}

/**
 * Get user conversation history.
 *
 * @returns {*}
 */
export function getUserHistoryApi() {
  return apiClient.get('/conversation?history&' + resolutionsConfig);
}

/**
 * Get user conversation.
 *
 * @returns {*}
 */
export function getUserConversationApi() {
  return apiClient.get('/conversation?' + resolutionsConfig);
}

/**
 * @param formData
 * @returns {*}
 */
export function replyConversationApi(formData) {
  return apiClient.post('/conversation/reply', formData);
}
