import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const VideoChat = () => (
  <>
    <div id="video-chat-wrapper" className="video-chat-wrapper communication-inner-wrapper">
      <div className="communication-channel-icon">
        <img src="/images/lp/promo6/girl_with_cup.png" alt="video-chat-icon" />
      </div>

      <p className="text-center mb-0">Upgrade to VIP+ for access to this feature.</p>

      <div className="channel-chat-btn">
        <p className="text-center">
          <a
            className="communication-link link-underline"
            href="/vip-ykaxcbtz"
            target="_blank"
          >
            Learn More
          </a>
        </p>
      </div>
    </div>
  </>
);

export default ErrorBoundaryDecorator()(VideoChat);
