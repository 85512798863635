import React, { PureComponent } from 'react';

import LayoverComponent from './components/LayoverComponent';
import InvalidEmail from './layovers/events/InvalidEmail';

class EventProcessor extends PureComponent {
  renderInvalidEmailEvent = () => {
    const invalidEmail = window.__SERVER_DATA__.invalid_email;
    if (!invalidEmail || !invalidEmail.email) {
      return null;
    }
    return (
      <LayoverComponent
        openByDefault
        modalSettings={{
          shouldCloseOnOverlayClick: true,
          shouldCloseOnEsc: true,
          className: 'newsletter-form-wrapper',
        }}
        LayoverContentComponent={InvalidEmail}
        layoverContentProps={{
          invalidEmail: invalidEmail.email,
        }}
      />
    );
  };


  render() {
    return (
      <React.Fragment>
        {this.renderInvalidEmailEvent()}
      </React.Fragment>
    );
  }
}

export default EventProcessor;
