import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Message from './Message';

const BlockMessages = ({
  isLoading,
  messages,
  unreadMessagesCount,
  needToShowUnread,
  onMessageRead,
  isHistoryChat,
}) => {
  const [state, setState] = useState({
    isPreviewModalOpen: false,
    body: {},
  });

  const onPreviewClick = (file) =>
    setState({
      isPreviewModalOpen: true,
      body: file,
    });

  const closePopup = () =>
    setState({
      isPreviewModalOpen: false,
      body: {},
    });

  const formattedContent = (content) => content.replace('contenteditable="true"', '');

  const renderPreviewModalBody = () => {
    switch (state.body.previewType) {
      case 'email':
        return (
          <div className="email-inner-wrapper">
            <div className="email-subject">
              <span>Email subject: </span> {state.body.subject}
            </div>

            <div
              className="email-content"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: formattedContent(state.body.message) }}
            />
          </div>
        );
      case 'file':
        return (
          <img
            className="attachment-modal-image"
            src={state.body.link}
            alt={state.body.display_name || state.body.origin_name}
          />
        );
      default:
        return null;
    }
  };

  const renderChatContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (messages.length === 0) {
      return (
        <div className="communication-inner-wrapper">
          {!isHistoryChat && (
            <div className="communication-channel-icon">
              <img src="/images/icons/letter-icon.svg" alt="chat-icon" />
            </div>
          )}

          <p className="text-center">
            {isHistoryChat
              ? 'No messages here yet...'
              : 'You can start a chat with us below. Let us know how we can help.'}
          </p>
        </div>
      );
    }

    const unreadMessagesNum = unreadMessagesCount > 9 ? '9+' : unreadMessagesCount;

    const unreadLabel = (
      <div className="chat-content__count-label" key="unread-label">
        <div>{unreadMessagesNum} unread</div>
      </div>
    );

    const unreadMessagesNumbers = messages.length - unreadMessagesCount;
    const items = [];

    for (let i = 0; i < messages.length; i++) {
      const itemData = messages[i];

      const item = <Message key={itemData.id} {...itemData} onPreviewClick={onPreviewClick} />;

      if (unreadMessagesNumbers === i && unreadMessagesCount > 0 && needToShowUnread) {
        items.push(unreadLabel);
      }

      items.push(item);
    }

    return items;
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        className={`chat-content ${messages.length === 0 ? 'chat-content--flex' : ''}`}
        ref={(inst) => inst && inst.scrollTo(0, inst.scrollHeight)}
        onClick={onMessageRead}
      >
        {renderChatContent()}
      </div>

      <ReactModal
        overlayClassName="modal-overlay"
        bodyOpenClassName="noScroll"
        className="attachment-modal-window"
        isOpen={state.isPreviewModalOpen}
        onRequestClose={closePopup}
      >
        <div className="email-preview-wrapper">
          <button
            type="button"
            tabIndex="0"
            onClick={closePopup}
            className="btn-close button-close"
          />

          {renderPreviewModalBody()}
        </div>
      </ReactModal>
    </>
  );
};

BlockMessages.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.oneOf([null])]),
  isLoading: PropTypes.bool,
  needToShowUnread: PropTypes.bool,
  isHistoryChat: PropTypes.bool,
};

BlockMessages.defaultProps = {
  messages: null,
  isLoading: false,
  needToShowUnread: false,
  isHistoryChat: false,
};

const mapStateToProps = ({ communications: { unreadMessagesCount } }) => ({ unreadMessagesCount });

export default ErrorBoundaryDecorator()(connect(mapStateToProps)(BlockMessages));
