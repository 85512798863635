const usePageVisibility = () => {
  const itemsInCart = window.__SERVER_DATA__.itemsCountInCart;

  const showCartButton = () =>
    itemsInCart > 0 &&
    !/^\/checkout/.test(window.location.pathname) && // checkout page
    !/^#\/package\//.test(window.location.hash) && // booking and room details pages
    !/^\/password/.test(window.location.pathname); // password reset

  const previewPage = () => /^\/preview/.test(window.location.pathname);
  const signInPage = () => /^\/sign-in/.test(window.location.pathname);

  return { itemsInCart, showCartButton, previewPage, signInPage };
};

export default usePageVisibility;
