import React from 'react';

const PARAMS = {
  title: 'Unauthorized',
  message: 'You should login to view this page',
};

const UnAuthorised = () => (
  <>
    <div className="message_title">
      {PARAMS.title}
    </div>

    <div className="title">
      {PARAMS.message}
    </div>
  </>
);

export default UnAuthorised;
