import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import echoClient from '@js/echoClient';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import TabNotificationsListener from '@js/services/TabNotificationsListener';
import { alert } from '@utils/Dialogs';
import {
  replyConversationApi,
  setMessagesShowedApi,
} from '@jsv2/utils/CommunicationUtils/APIRequests';
import { setCommunicationDataAction, setUnreadMessagesCountAction } from '@js/ducks/communications';
import { setAdConfig } from '@js/ducks/ads';
import { isImpersonated } from '@jsv2/utils/UserUtils/userStatuses';
import {
  EXPIRED_SESSION_CHAT_MESSAGE_KEY,
  EXPIRED_SESSION_IS_LIVE_CHAT_KEY,
  EXPIRED_SESSION_ALERT_MESSAGE,
  EXPIRED_SESSION_ALERT_BTN_CONTENT,
  EXPIRED_SESSION_RELOADED_KEY,
  loginLayoverAdConfig,
} from '@jsv2/Enums/ExpiredSessionEnums';
import BlockMessages from './BlockMessages';
import Footer from './Footer';

const { userRoles, user } = window;

const tabNotifications = new TabNotificationsListener();

const Chat = (props) => {
  const {
    communicationMessages,
    unreadMessagesCount,
    isLiveChat,
    defaultMessage,
    messageOptions,
    setCommunicationData,
    setUnreadMessagesCount,
    getHistory,
    getUserConversation,
    setAdConfigHandler,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isMessageAbleToSend, setIsMessageAbleToSend] = useState(true);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [needToShowUnread, setNeedToShowUnread] = useState(true);

  const decorateNToBr = (message) => message && message.replaceAll('\n', '<br />');

  const getTempId = () => communicationMessages.length + '_' + new Date().getTime();

  const createTempMessage = ({ message, files }) => ({
    is_customer_author: true,
    message: decorateNToBr(message),
    created_at: null,
    source_type: 'chat',
    files: files || [],
    opened_at: '',
    options: [],
    id: getTempId(),
  });

  const setMessagesShowed = (messageIds) => {
    if (messageIds.length === 0) return null;

    return setMessagesShowedApi(messageIds)
      .then(({ data }) => {
        setCommunicationData('messages', data.messages);

        setTimeout(() => {
          setNeedToShowUnread(true);
        });
      })
      .catch((err) => {
        Logger.error(err);
      });
  };

  const setNewMessagesShowed = () => {
    if (communicationMessages) {
      const ids = communicationMessages
        .filter((message) => message.source_type === 'chat')
        .filter((message) => message.opened_at === null)
        .filter((message) => !message.is_customer_author)
        .map((message) => message.id);

      setMessagesShowed(ids);
    }
  };

  const checkBothSendSimultaneously = (messages, hideUnreadLabel) => {
    const stylistMessages = messages
      .filter((message) => message.source_type === 'chat')
      .filter((message) => !message.is_customer_author);

    const lastStylistMessage = stylistMessages[stylistMessages.length - 1];
    const lastCommunicationMessage = messages[messages.length - 1];

    if (
      lastStylistMessage &&
      lastCommunicationMessage &&
      lastStylistMessage.id !== lastCommunicationMessage.id &&
      lastStylistMessage.opened_at === null &&
      hideUnreadLabel
    ) {
      setNeedToShowUnread(false);
      setNewMessagesShowed();
    }
  };

  const triggerLoginLayover = () => {
    alert(EXPIRED_SESSION_ALERT_MESSAGE, {
      labelOk: EXPIRED_SESSION_ALERT_BTN_CONTENT,
      onOk: () => {
        setAdConfigHandler(loginLayoverAdConfig);
      },
    });
  };

  const processExpiredSession = (message) => {
    setCommunicationData('messages', communicationMessages);
    setCommunicationData('input', message);

    localStorage.setItem(EXPIRED_SESSION_CHAT_MESSAGE_KEY, message);
    localStorage.setItem(EXPIRED_SESSION_IS_LIVE_CHAT_KEY, isLiveChat);

    triggerLoginLayover();
  };

  const replyConversation = (formData) => {
    setIsMessageAbleToSend(false);

    setTimeout(() => {
      setIsMessageAbleToSend(true);
    }, 3000);

    const { context_type: contextType, entity_id: entityId, entity_type: entityType } = props;

    const tempMessage = createTempMessage(formData);
    const contextTitle = window.document.title;
    const contextUrl = window.location.href;

    setCommunicationData('messages', [...communicationMessages, tempMessage]);

    return replyConversationApi({
      ...formData,
      context_type: contextType,
      context_title: contextTitle,
      context_url: contextUrl,
      entity_id: entityId,
      entity_type: entityType,
      message_type: 'message',
    })
      .then(({ data: { messages } }) => {
        setCommunicationData('messages', messages);

        checkBothSendSimultaneously(messages, true);

        getHistory();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          processExpiredSession(formData.message);
        } else {
          Logger.error(err);
        }
      });
  };

  const updateConversationData = () => {
    getHistory();
    getUserConversation();
  };

  const onMessageRead = (delay = false) => {
    if (unreadMessagesCount > 0 && communicationMessages.length > 0 && !isImpersonated(userRoles)) {
      setNewMessagesShowed();

      if (delay) {
        setTimeout(() => {
          setUnreadMessagesCount(0);
        }, 3000);
      } else {
        setUnreadMessagesCount(0);
      }
    }
  };

  useEffect(() => {
    if (!isLoading && communicationMessages.length === 0) {
      tabNotifications.run();

      echoClient
        .channel(`private-App.User.${user.id}`)
        .listen('Conversation\\DispatchConversationMessageToCustomer', ({ data }) => {
          tabNotifications.update(data.messages);

          setUnreadMessagesCount(data.messages);
        })
        .listen('Conversation\\DispatchMessageShowedToCustomer', ({ data }) => {
          tabNotifications.update(data.messages);

          setUnreadMessagesCount(data.messages);
        })
        .listen('Conversation\\ConversationActiveStylistAssigned', () => {
          updateConversationData();
        })
        .listen('Conversation\\ConversationStylistCleared', () => {
          updateConversationData();
        });
    }

    if (!isLoading && communicationMessages.length > 0 && isLiveChat) {
      onMessageRead(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (communicationMessages.length > 0 && unreadMessagesCount > 0) {
      setUnreadMessagesCount();

      checkBothSendSimultaneously(communicationMessages, !isImpersonated(userRoles));
    }
  }, [communicationMessages]);

  useEffect(() => {
    if (communicationMessages.length === 0 && isLiveChat !== false) {
      setIsLoading(true);

      getUserConversation().then(() => {
        setIsLoading(false);

        echoClient
          .channel(`private-App.User.${user.id}`)
          .listen('Conversation\\DispatchConversationMessageToCustomer', ({ data }) => {
            setCommunicationData('messages', data.messages);

            getHistory();
          })
          .listen('Conversation\\DispatchMessageShowedToCustomer', ({ data }) => {
            setCommunicationData('messages', data.messages);

            getHistory();
          });
      });
    }

    if (communicationMessages.length > 0 && isLiveChat !== null) {
      if (!isLiveChat) {
        onMessageRead();
      } else {
        onMessageRead(true);
      }
    }
  }, [isLiveChat]);

  useEffect(() => {
    const isPageReloaded = localStorage.getItem(EXPIRED_SESSION_RELOADED_KEY) !== null;
    const expiredSessionChatMessage = localStorage.getItem(EXPIRED_SESSION_CHAT_MESSAGE_KEY);

    if (expiredSessionChatMessage !== null && isPageReloaded) {
      setCommunicationData('input', expiredSessionChatMessage);

      localStorage.removeItem(EXPIRED_SESSION_CHAT_MESSAGE_KEY);
    }
  }, []);

  return (
    <div className="chat-wrapper" data-qa-id="chat_block">
      <BlockMessages
        messages={communicationMessages}
        isLoading={isLoading}
        onMessageRead={() => onMessageRead(true)}
        needToShowUnread={needToShowUnread}
      />

      <Footer
        isMessageAbleToSend={isMessageAbleToSend}
        isFileUploading={isFileUploading}
        setIsFileUploading={setIsFileUploading}
        onMessageRead={onMessageRead}
        replyConversation={replyConversation}
        defaultMessage={defaultMessage}
        messageOptions={messageOptions}
      />
    </div>
  );
};

Chat.propTypes = {
  defaultMessage: PropTypes.string,
  getHistory: PropTypes.func.isRequired,
  getUserConversation: PropTypes.func.isRequired,
  context_type: PropTypes.string,
  entity_id: PropTypes.number,
  entity_type: PropTypes.string,
  isLiveChat: PropTypes.bool,
};

Chat.defaultProps = {
  defaultMessage: '',
  context_type: null,
  entity_id: null,
  entity_type: null,
  isLiveChat: null,
};

const mapStateToProps = ({ communications: { communicationMessages, unreadMessagesCount } }) => ({
  communicationMessages,
  unreadMessagesCount,
});

const mapDispatchToProps = {
  setUnreadMessagesCount: setUnreadMessagesCountAction,
  setCommunicationData: setCommunicationDataAction,
  setAdConfigHandler: setAdConfig,
};

export default ErrorBoundaryDecorator()(connect(mapStateToProps, mapDispatchToProps)(Chat));
