import moment from 'moment';

export const FIRST_DAY_OF_MONTH = moment().startOf('month');

export const DATE_FORMAT = {
  DAY: 'D',
  MONTH: 'YYYY-MM',
  FULL: 'YYYY-MM-DD',
  FULL_2: 'MMM DD, YYYY',
  FULL_3: 'MMMM DD, YYYY',
  FULL_4: 'MMMM DD YYYY',
  FULL_5: 'MMM DD YYYY',
  HEADER: 'MMMM YYYY',
  WITHOUT_YEAR: 'DD MMM',
  SHORT_WITH_COMMA: 'MMM DD, YYYY', // "Dec 05, 2024"
};

export const EMPTY_DATE_INPUT_VALUE = '0000-00-00';
