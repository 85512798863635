import React from 'react';
import PropTypes from 'prop-types';

import Chat from '@components/Chat';
import ConversationHistory from '@components/sections/stylist-page/CommunicationsSection/ConversationHistory';
import EmailForm from '@components/sections/stylist-page/CommunicationsSection/EmailForm';
import VideoChat from '@components/sections/stylist-page/CommunicationsSection/VideoChat';
import WhatsAppForm from '@components/sections/stylist-page/CommunicationsSection/WhatsAppForm';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const component = {
  chat: (props) => <Chat {...props} />,
  whatsapp: (props) => <WhatsAppForm {...props} />,
  email: (props) => <EmailForm {...props} />,
  video: (props) => <VideoChat {...props} />,
  history: (props) => <ConversationHistory {...props} />,
};
const CommunicationWrapper = React.forwardRef(({ activeTab, ...props }, ref) => (
  <div className="communication-wrapper" ref={ref}>
    {component[activeTab](props)}
  </div>
));

CommunicationWrapper.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default ErrorBoundaryDecorator()(CommunicationWrapper);
