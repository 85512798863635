import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';

import ChatFileUploader from '@components/Chat/ChatFileUploader';
import TextareaAutosize from 'react-textarea-autosize';
import { CONVERSATION_MESSAGES_SOURCE_CODES } from '@js/constants';
import { setCommunicationDataAction } from '@js/ducks/communications';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Maximum number of rows before the scroll appears
 *
 * @type {number}
 */
const maxRows = 5;

const Footer = ({
  isMessageAbleToSend,
  isFileUploading,
  setIsFileUploading,
  defaultMessage,
  inputMessage,
  messageOptions,
  replyConversation,
  onMessageRead,
  setCommunicationData,
}) => {
  const [message, setMessage] = useState(defaultMessage || inputMessage);
  const [fileIds, setFileIds] = useState([]);
  const [files, setFiles] = useState([]);

  const FormikRef = useRef(null);

  const resetFormValues = () => {
    setMessage('');
    setFileIds([]);
    setFiles([]);
  };

  const isMessageFieldEmpty = (messageField) =>
    !messageField || !messageField.replace(/\s/g, '').length;

  const validateData = (data, setFieldError) => {
    let result = true;

    if (!data.file_ids.length && isMessageFieldEmpty(data.message)) {
      setFieldError('message', 'Message is empty');
      result = false;
    }

    return result;
  };

  const filterData = (formDataObj) =>
    Object.keys(formDataObj).reduce((data, key) => {
      const value = formDataObj[key];

      if (!value || (Array.isArray(value) && !value.length)) {
        return data;
      }

      return { ...data, [key]: value };
    }, {});

  const handleSubmit = (values, actions) => {
    if (isMessageAbleToSend && !isFileUploading) {
      onMessageRead();

      if (!validateData(values, actions.setFieldError)) {
        return;
      }

      resetFormValues();

      replyConversation({
        ...filterData(values),
        source_type: CONVERSATION_MESSAGES_SOURCE_CODES.CHAT,
        options: messageOptions,
      });
    }
  };

  const removeAttachment = (file, values) => {
    setFiles(values.files.filter((item) => item.id !== file.id));
    setFileIds(values.file_ids.filter((fileId) => fileId !== file.id));
  };

  const onSubmitWithHotKeys = (e) => {
    const enter = e.key === 'Enter' || e.key === 'NumpadEnter';
    const tabEnter = e.key === 'Tab' && enter;
    const commandEnter = (e.metaKey || e.key === 'Meta') && enter;
    const ctrlEnter = e.ctrlKey && enter;
    const shiftEnter = e.shiftKey && enter;

    if (tabEnter || commandEnter || ctrlEnter || shiftEnter) {
      e.preventDefault();
      FormikRef.current.submitForm();
    }
  };

  useEffect(() => {
    if (inputMessage !== '') {
      setMessage(inputMessage);
    }
  }, [inputMessage]);

  useEffect(() => {
    if (defaultMessage !== '') {
      setMessage(defaultMessage);
    }
  }, [defaultMessage]);

  useLayoutEffect(() => () => setCommunicationData('input', FormikRef.current.values.message), []);

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        message,
        file_ids: fileIds,
        files,
      }}
      innerRef={FormikRef}
    >
      {({ errors, values, setFieldValue }) => (
        <Form onKeyDown={onSubmitWithHotKeys}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div className="chat-footer" onClick={() => onMessageRead(true)}>
            <ChatFileUploader
              setValue={(newFiles) => {
                setFiles(newFiles);
                setFileIds(newFiles.map((file) => file.id));
              }}
              attachment={values.files}
              removeAttachment={(currentFile) => removeAttachment(currentFile, values)}
              setIsFileUploading={setIsFileUploading}
            />

            <div className="chat-input-wrapper">
              <TextareaAutosize
                type="text"
                name="message"
                maxRows={maxRows}
                className={classNames('chat-input', { 'with-error': getIn(errors, 'message') })}
                placeholder="Type your message..."
                value={message}
                onChange={(e) => {
                  setFieldValue('message', e.target.value);
                  setMessage(e.target.value);
                }}
              />
            </div>

            <ErrorMessage className="chat-input-message-error" component="div" name="message" />

            <button type="submit" className="btn-chat send" id="send-message" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

Footer.propTypes = {
  defaultMessage: PropTypes.string,
};

Footer.defaultProps = {
  defaultMessage: '',
};

const mapStateToProps = ({ communications: { inputMessage } }) => ({
  inputMessage,
});

const mapDispatchToProps = {
  setCommunicationData: setCommunicationDataAction,
};

export default ErrorBoundaryDecorator()(connect(mapStateToProps, mapDispatchToProps)(Footer));
