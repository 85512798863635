import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import CommunicationsSection from '@components/sections/stylist-page/CommunicationsSection';

/**
 * Render chat
 *
 * @param heading
 * @param defaultMessage
 * @param handleCloseChat
 * @param rest
 * @return {*}
 *
 * @constructor
 */
const FindChat = ({ heading, defaultMessage, handleCloseChat, ...rest }) => (
  <>
    {handleCloseChat && <button type="button" className="btn-close" onClick={handleCloseChat} />}

    <CommunicationsSection heading={heading} defaultMessage={defaultMessage} {...rest} />
  </>
);

FindChat.propTypes = {
  heading: PropTypes.string,
  defaultMessage: PropTypes.string,
  handleCloseChat: PropTypes.func,
};

FindChat.defaultProps = {
  heading: '',
  handleCloseChat: null,
  defaultMessage: '',
};

export default ErrorBoundaryDecorator()(FindChat);
