import React from 'react';
import PropTypes from 'prop-types';

import ImageAttachment from '@components/Chat/Attachment/ImageAttachment';
import DocumentAttachment from '@components/Chat/Attachment/DocumentAttachment';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Attachment = ({ file, onImageClick }) => {
  switch (file.type) {
    case 'image':
      return <ImageAttachment file={file} onClick={onImageClick} />;
    case 'file':
      return <DocumentAttachment file={file} />;
    default:
      return '';
  }
};

Attachment.propTypes = {
  file: PropTypes.object.isRequired,
  onImageClick: PropTypes.func,
};

Attachment.defaultProps = {
  onImageClick: () => {},
};

export default ErrorBoundaryDecorator()(Attachment);
