import React from 'react';

const PARAMS = {
  title: 'it\'s lost!',
};

const { shared_data: { defaultHomePageUrl } } = window.__SERVER_DATA__;

const NotFound = () => (
  <>
    <div className="message_title">
      {PARAMS.title}
    </div>

    <div className="title">
        The page you request cannot be found.
      <br />
        Maybe start over from the <a href={defaultHomePageUrl}>home page</a>?
    </div>
  </>
);

export default NotFound;
