import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';
import Modal from '@components/common/Modal';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

ReactModal.setAppElement('#root');

class SetEmailOnlyPasswordForm extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    password: '',
    confirmPassword: '',
    statusText: '',
    isErrorOccurred: false,
  };

  modalSettings = {
    contentLabel: 'Password',
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: true,
    overlayClassName: 'modal-overlay password-request__overlay',
  };

  passwordInputRef = null;
  confirmPasswordInputRef = null;
  submitBtnRef = null;

  constructor(props) {
    super(props);

    this.passwordInputRef = React.createRef();
    this.confirmPasswordInputRef = React.createRef();
    this.submitBtnRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setError(this.props.error);
    }
  }

  afterOpenModal = () => this.passwordInputRef.current && this.passwordInputRef.current.focus();

  onKeyPressHandler = (e, currentInput) => {
    if (e.key === 'Enter') {
      const { password, confirmPassword } = this.state;

      if (currentInput === 'password' && password.trim().length > 0) {
        this.confirmPasswordInputRef.current.focus();
      } else if (currentInput === 'confirmPassword' && confirmPassword.trim().length > 0) {
        this.submitBtnRef.current.click();
      }
    }
  };

  handleSubmit = () => {
    const { password, confirmPassword } = this.state;

    let hasError = false;

    if (password.trim().length === 0) {
      this.setError('Password field is empty');

      hasError = true;
    } else if (password.trim().length < 6) {
      this.setError('The password must be at least 6 characters');

      hasError = true;
    } else if (confirmPassword.trim().length === 0) {
      this.setError('Confirm password field is empty');

      hasError = true;
    } else if (password !== confirmPassword) {
      this.setError("Confirm password doesn't match");

      hasError = true;
    }

    if (!hasError) {
      this.props.onSubmit(password);
    }
  };

  onRequestClose = () => {
    this.props.onRequestClose();
    this.resetForm();
  };

  setError = (errorTest) => {
    this.setState({
      statusText: errorTest,
      isErrorOccurred: true,
    });
  };

  resetForm = (needFullReset = true) => {
    if (needFullReset) {
      this.setState({
        statusText: '',
        isErrorOccurred: false,
        password: '',
        confirmPassword: '',
      });
    } else {
      this.setState({
        statusText: '',
        isErrorOccurred: false,
      });
    }
  };

  renderContent() {
    return (
      <div className="password-request-block">
        {this.state.statusText ? this.renderStatusTextMessage() : this.renderPasswordPrompt()}
      </div>
    );
  }

  renderStatusTextMessage() {
    return (
      <>
        <div
          className={`layover-popup__title fsz-22 ${
            this.state.isErrorOccurred ? 'text-danger' : ''
          }`}
        >
          {this.state.statusText}
        </div>
        <button
          onClick={() => this.resetForm(false)}
          type="button"
          className="layover-popup__button"
        >
          {t('Ok')}
        </button>
      </>
    );
  }

  renderPasswordPrompt() {
    return (
      <form>
        <h2 className="layover-popup__title">{t('Enter your new password')}</h2>

        <div className="form-field">
          <div className="input">
            <input
              type="password"
              name="password"
              className="custom-input"
              placeholder="Password"
              value={this.state.password}
              onKeyPress={(e) => this.onKeyPressHandler(e, 'password')}
              onChange={(e) => this.setState({ password: e.target.value })}
              ref={this.passwordInputRef}
            />
            {/* <span role="button" tabIndex="0" className="edit-input" /> */}
          </div>
        </div>

        <div className="form-field">
          <div className="input">
            <input
              type="password"
              name="confirm_password"
              placeholder="Confirm password"
              className="custom-input"
              value={this.state.confirmPassword}
              onKeyPress={(e) => this.onKeyPressHandler(e, 'confirmPassword')}
              onChange={(e) => this.setState({ confirmPassword: e.target.value })}
              ref={this.confirmPasswordInputRef}
            />
            {/* <span role="button" tabIndex="0" className="edit-input" /> */}
          </div>
        </div>

        <div className="content-footer">
          <button
            onClick={this.handleSubmit}
            type="button"
            className="layover-popup__button"
            ref={this.submitBtnRef}
          >
            {t('Submit')}
          </button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <Modal
        {...this.modalSettings}
        isOpen={this.props.isOpen}
        withCloseButton
        size="lg"
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.onRequestClose}
        dataQaModalId="qa_password_popup"
      >
        {this.props.isLoading ? <Spinner /> : this.renderContent()}
      </Modal>
    );
  }
}

export default ErrorBoundaryDecorator()(SetEmailOnlyPasswordForm);
