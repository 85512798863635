import React from 'react';
import PropTypes from 'prop-types';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const ImageAttachment = ({ file, onClick }) => (
  <Image
    className="chat-attachment-file"
    data={file}
    config={{
      size: IMAGE_SIZES.SMALL, // USE_RESOLUTION: IMAGE_W350 TODO: WHEN BACK-END GIVE US DATA CHANGE
    }}
    background
  >
    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
    <a
      onClick={(e) => {
        e.preventDefault();
        onClick({ ...file, previewType: 'file' });
      }}
      href={file.link}
    />
  </Image>
);

ImageAttachment.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ImageAttachment.defaultProps = {
  onClick: () => {},
};

export default ErrorBoundaryDecorator()(ImageAttachment);
