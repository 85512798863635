import React from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Modal from '@components/common/Modal';

/**
 * Content for the message
 *
 * @type {object}
 */
const MESSAGE_CONTENT = {
  image: '/images/hard-hat.svg',
  heading: 'Reserved for VIP+ Members',
  tagline:
    'This feature is part of our VIP+ Membership where members get a personal travel stylist and a lot more (see list of benefits)',
  buttonText: 'Upgrade Today - Free Trial',
};

/**
 * Popup with a message why a chat channel is disabled
 *
 * @param isModalOpen
 * @param setModalOpen
 *
 * @return {*}
 *
 * @constructor
 */
const WarningMessage = ({ isModalOpen, setModalOpen }) => {
  const handleButtonClick = () => (window.location.href = '/profile#/membership');

  const closeModal = () => setModalOpen(false);

  return (
    <Modal
      isOpen={isModalOpen}
      withCloseButton
      onRequestClose={closeModal}
      className="landing-modal-window travel-finds-msg"
    >
      <div className="layover-wrapper landing-layover-wrapper">
        <BaseMessageTemplate handleClick={handleButtonClick} {...MESSAGE_CONTENT} />
      </div>
    </Modal>
  );
};

WarningMessage.propTypes = {
  isModalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
};

WarningMessage.defaultProps = {
  isModalOpen: false,
};

export default ErrorBoundaryDecorator()(WarningMessage);
