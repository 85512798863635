import React from 'react';
import PropTypes from 'prop-types';

import { combineStorageUrl } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import FILE_ICONS from '@jsv2/Enums/FileIconEnums';

const DocumentAttachment = ({ file, onClick }) => {
  const { path, name, display_name: displayName, origin_name: originName } = file;

  function fileIcon(fileName) {
    // eslint-disable-next-line no-bitwise
    const fileExt = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
    const fileTypes = Object.keys(FILE_ICONS);

    for (const type of fileTypes) {
      if (FILE_ICONS[type].some((ext) => ext === fileExt)) {
        return type;
      }
    }

    return 'file';
  }

  return (
    <div className="msg-row-attachment attachment_file avatar-img ml-5">
      <ul className="attachment_file_list">
        <li className="attachment_file_list_item">
          <div className={`file-icon ${fileIcon(name)}`} />

          <a
            className="attachment_file_link"
            onClick={() => {
              onClick(file);
            }}
            href={combineStorageUrl(path, name)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {displayName || originName}
          </a>
        </li>
      </ul>
    </div>
  );
};

DocumentAttachment.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

DocumentAttachment.defaultProps = {
  onClick: () => {},
};

export default ErrorBoundaryDecorator()(DocumentAttachment);
