import React from 'react';

import { renderPage } from '@jsv2/utils/PageUtils/RenderPageUtils';
import App from '@js/App';

import BadRequest from './Codes/400';
import UnAuthorised from './Codes/401';
import Forbidden from './Codes/403';
import NotFound from './Codes/404';
import ServerError from './Codes/500';

const { code } = window.__SERVER_DATA__;

const Error = () => {
  const getComponent = () => {
    let component;

    switch (code) {
      case 400:
        component = <BadRequest />;
        break;
      case 401:
        component = <UnAuthorised />;
        break;
      case 403:
        component = <Forbidden />;
        break;
      case 404:
        component = <NotFound />;
        break;
      default:
        component = <ServerError />;
    }

    return component;
  };

  return (
    <App className="app static-page app__error-page" isErrorPage>
      <div className="error-page">
        <div className="content">
          <div className="code">
            <img src="/images/suitcase-404-page.svg" alt="page not found" />
          </div>

          {getComponent()}
        </div>
      </div>
    </App>
  );
};

renderPage(Error);
