import React, { useContext, useEffect, useState, useCallback } from 'react';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Reserve = () => {
  const screenTypeContext = useContext(ScreenTypeContext);
  const [isHide, setIsHide] = useState(false);

  const handleButtonShowing = useCallback(() => {
    const deviceHeight = window.innerHeight;
    const scrollHeight = deviceHeight + window.pageYOffset;
    const bookingSection = document.getElementById('booking-options');
    const bookingSectionHeight = bookingSection && bookingSection.clientHeight;
    const bookingSectionOffsetTop = bookingSection && bookingSection.offsetTop + deviceHeight / 2;
    const bookingSectionOffsetBottom = bookingSectionOffsetTop + bookingSectionHeight;

    setIsHide(scrollHeight > bookingSectionOffsetTop && scrollHeight < bookingSectionOffsetBottom);
  }, []);

  useEffect(() => {
    if (isMobile(screenTypeContext)) {
      handleButtonShowing();
      window.addEventListener('scroll', handleButtonShowing, { passive: true });
    }

    return () => {
      window.removeEventListener('scroll', handleButtonShowing);
    };
  }, [handleButtonShowing, screenTypeContext]);

  const scrollTo = () => {
    const headerHeight = document.getElementsByClassName('main-header')[0].clientHeight;
    const sectionToScroll = document.getElementById('booking-options').offsetTop - headerHeight;
    window.scroll({ top: sectionToScroll, behavior: 'smooth' });
  };

  return (
    <button
      type="button"
      className={classNames('navlist__btn navlist__btn--black navlist__btn--reserve', {
        hide: isHide,
      })}
      data-qa-id="reserve-button"
      onClick={() => scrollTo()}
    >
      Reserve now
    </button>
  );
};

export default ErrorBoundaryDecorator()(Reserve);
