import React from 'react';

const PARAMS = {
  title: 'Server error',
  message: 'Something bad happened and we cannot process your request. Don`t worry, our technicians have been'
    + ' notified and are working hard to eliminate the problem! Please make another attempt some time soon.',
};

const ServerError = () => (
  <>
    <div className="message_title">
      {PARAMS.title}
    </div>

    <div className="title">
      {PARAMS.message}
    </div>
  </>
);

export default ServerError;
