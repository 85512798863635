import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Spinner from '../../components/Spinner';

class InvalidEmail extends Component {
  static propTypes = {
    invalidEmail: PropTypes.string.isRequired,
  };

  handleSubmit = (values, actions) => {
    const { onClose } = this.props;
    actions.setSubmitting(true);
    window.location.href = '/profile#/account-settings';
    onClose();
  };

  render() {
    const { invalidEmail, onClose } = this.props;
    return (
      <div className="newsletter-form email-invalid">
        <div>
          <div className="layover-header">
            <div className="layover-popup__title">{t('Oh no')}!</div>
          </div>
          <div className="form email-not-valid">
            <div className="message">{t('We tried emailing you at')}</div>
            <div className="email-title">{invalidEmail}</div>
            <div className="horizontal-red-line" />
            <div className="message">{t('but the email bounced back.')}</div>
            <div className="message">
              {t(
                'Would you like to update your email address to receive your personalized Travel Finds™ & get access to VIP Exclusives™?',
              )}
            </div>
          </div>
          <Formik onSubmit={this.handleSubmit} initialValues={{}}>
            {({ isSubmitting }) => {
              if (isSubmitting) {
                return <Spinner />;
              }
              return (
                <Form>
                  <div className="content-footer text-center ">
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                      className="layover-popup__button layover-popup__button--dark"
                      onClick={onClose}
                      data-qa-id={lang.get('common.no')}
                    >
                      {lang.get('common.no')}
                    </button>
                    <button
                      className="layover-popup__button"
                      type="submit"
                      data-qa-id={lang.get('common.yes')}
                    >
                      {lang.get('common.yes')}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default InvalidEmail;
