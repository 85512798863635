import React, { Component } from 'react';

import Cookies from 'browser-cookies';
import SetEmailOnlyPasswordForm from '@components/SetEmailOnlyPasswordForm';
import PropTypes from 'prop-types';
import apiClient from '@js/apiClient';

export const PASSWORD_REQUEST = 'password-requested';

class PasswordRequest extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  state = {
    isOpen: true,
    passwordError: '',
    isLoading: false,
  };

  onSubmitHandler = (password) => {
    const {
      user: { initial_update_secret_key: InitialUpdateSecretKey },
    } = this.props;

    const postData = {
      password,
      secret_key: InitialUpdateSecretKey,
    };

    if (!InitialUpdateSecretKey) {
      // eslint-disable-next-line no-console
      console.error('No initial update secret key found');
    } else {
      this.setState({ isLoading: true });

      apiClient
        .put('/user/update', postData)
        .then(() => {
          this.setState({ isOpen: false });
        })
        .catch(({ response: { data } }) => {
          this.handleSubmitError(data);
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleSubmitError = ({ errors }) => {
    if (errors && errors.password) {
      this.setState({ passwordError: errors.password.join(' <br />') });
    }
  };

  onRequestCloseHadler = () => {
    this.setState({ isOpen: false });

    Cookies.set(PASSWORD_REQUEST, 'true');
  };

  renderPasswordRequest = () => {
    const { user } = this.props;

    return (
      <SetEmailOnlyPasswordForm
        user={user}
        onSubmit={this.onSubmitHandler}
        onRequestClose={this.onRequestCloseHadler}
        isOpen={this.state.isOpen}
        error={this.state.passwordError}
        isLoading={this.state.isLoading}
      />
    );
  };

  render() {
    const {
      user: { initial_update_secret_key: InitialUpdateSecretKey },
    } = this.props;
    const shouldRender = !!InitialUpdateSecretKey && !Cookies.get(PASSWORD_REQUEST);

    return <React.Fragment>{shouldRender && this.renderPasswordRequest()}</React.Fragment>;
  }
}

export default PasswordRequest;
