import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import TopLinkItem from '@jsv2/components/Header/components/TopLinkItem';
import { getBackLinkToGo } from '@jsv3/utils/urlUtils';

/**
 * Render a link button "About us"
 *
 * @return {*}
 *
 * @constructor
 */
const AboutUs = ({ shouldGoBack }) => (
  <TopLinkItem
    id="about-us"
    title="About Us"
    href={'/about-us' + (shouldGoBack ? `?go_back_url=${getBackLinkToGo()}` : '')}
  />
);

AboutUs.propTypes = {
  shouldGoBack: PropTypes.bool,
};

AboutUs.defaultProps = {
  shouldGoBack: false,
};

export default ErrorBoundaryDecorator()(AboutUs);
