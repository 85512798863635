import React, { useState } from 'react';
import { connect } from 'react-redux';

import ToastNotification from '@components/common/ToastNotification';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const WhatsAppForm = ({ userStylist }) => {
  const goToWhatsApp = () => window.open(`https://wa.me/${userStylist.whatsappPhone}`, '_blank');

  const [isToastVisible, showToast] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);

    showToast(true);
  };

  return (
    <>
      <p>
        Here&#39;s a number you can use to reach me on WhatsApp: &ensp;
        <button
          type="button"
          className="communication-link"
          onClick={() => copyToClipboard(userStylist.whatsappPhone)}
        >
          {userStylist.whatsappPhone}
        </button>
        &ensp; just add me as a contact or click below to start the conversation
      </p>

      <button type="button" onClick={goToWhatsApp} className="btn-red communication-btn">
        go to WhatsApp
      </button>

      <ToastNotification
        isVisible={isToastVisible}
        content="The number copied to your clipboard"
        onClose={() => showToast(false)}
      />
    </>
  );
};

const mapStateToProps = ({ communications: { userStylist } }) => ({ userStylist });

export default ErrorBoundaryDecorator()(connect(mapStateToProps)(WhatsAppForm));
