import React from 'react';

import { TRAVEL_FINDS_URL } from '@jsv3/enums/urlEnums';
import TopLinkItem from '../components/TopLinkItem';

const TravelFinds = () => (
  <TopLinkItem href={TRAVEL_FINDS_URL} id="travel-finds-button" title="My Travel Finds" />
);

export default TravelFinds;
