import React from 'react';

import TopLinkItem from '../components/TopLinkItem';

const BucketList = () => (
  <TopLinkItem
    href="/bucket-list"
    id="wish-list-button"
    title="My Bucket List"
  />
);

export default BucketList;
