import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ToastNotification = ({ content, isVisible, duration, onClose }) => {
  const [isToastVisible, showToast] = useState(false);

  useEffect(() => showToast(isVisible), [isVisible]);
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }

    return () => {};
  });

  return (
    isToastVisible && (
      <div className="toast-wrapper">
        <div className="toast-content">{content}</div>

        <button
          type="button"
          className="toast-close"
          onClick={() => onClose()}
          aria-label="Close"
        />
      </div>
    )
  );
};

ToastNotification.propTypes = {
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
};

ToastNotification.defaultProps = {
  duration: 2000,
};

export default ToastNotification;
