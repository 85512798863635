import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Communication panel with all available communication channels
 *
 * @param activeTab
 * @param handleClickChannel
 * @param channels
 * @param hidePanel
 *
 * @returns {*}
 *
 * @constructor
 */
const CommunicationsPanel = ({ activeTab, handleClickChannel, channels, hidePanel }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <div className={`stylist-contacts ${hidePanel ? 'hide' : ''}`}>
      {channels.map((channel) => (
        <button
          key={channel.name}
          type="button"
          className={classNames(
            'contact-link',
            `stylist-${channel.name}`,
            { active: activeTab === channel.name },
            { disabled: !channel.enabled },
          )}
          onClick={() => handleClickChannel(channel)}
          data-qa-id={`qa_btn_${channel.name}`}
        >
          {!isMobile(screenTypeContext) ? channel.title : channel.mobileTitle}
        </button>
      ))}
    </div>
  );
};

CommunicationsPanel.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleClickChannel: PropTypes.func.isRequired,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  hidePanel: PropTypes.bool.isRequired,
};

export default ErrorBoundaryDecorator()(CommunicationsPanel);
