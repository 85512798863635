import React, { useEffect, useState } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Modal from '@components/common/Modal';
import { alert } from '@utils/Dialogs';
import {
  onSendVerificationChannel,
  onConfirmVerificationChannel,
} from '@jsv3/utils/api/channelVerificationAPI';
import OverlaySpinner from '@components/OverlaySpinner';
import InputField from '@jsv3/components/atoms/InputField';
import Button from '@jsv3/components/atoms/Button';
import { NOTIFICATION_CHANNELS } from '../config';

const ConfirmedChannelModal = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [codeValue, setCodeValue] = useState('');
  const [showInputCode, setShowInputCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsModalOpened(true);
  }, []);

  /**
   * @return {void}
   */
  const onSendConfirm = () => {
    setError('');
    setIsLoading(true);

    onConfirmVerificationChannel(codeValue)
      .then((response) => {
        if (response.data.error) {
          setError('invalid code');
        } else {
          setIsModalOpened(false);

          alert('Success. Thank You!');
        }
      })
      .catch(({ response: { data } }) => {
        alert(t(data.message));
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * @param {object} channel
   * @return {void}
   */
  const onSendNotificationType = (channel) => {
    setSelectedChannel(channel);
    setIsLoading(true);

    onSendVerificationChannel(channel.type)
      .then(() => {
        setShowInputCode(true);
      })
      .catch(({ response: { data } }) => {
        alert(t(data.message));
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * @param e
   */
  const onChangeCodeInput = (e) => {
    setCodeValue(e.target.value);

    if (error) {
      setError('');
    }
  };

  /**
   * @return void
   */
  const onChangeChannel = () => {
    setShowInputCode(false);
    setError('');
    setCodeValue('');
  };

  /**
   * @return {JSX.Element}
   */
  const renderChannels = () => (
    <>
      <p className="m-0 form-subtitle">Select a channel to send a verification code:</p>

      <div className="btns-wrapper">
        {NOTIFICATION_CHANNELS.map((channel) => (
          <button
            key={channel.type}
            type="button"
            data-qa-id={`qa_button_${channel.type}`}
            onClick={() => onSendNotificationType(channel)}
          >
            <img
              src={channel.icon}
              alt={`${channel.type} icon`}
              className={`${channel.type}-icon`}
            />
            {channel.title}
          </button>
        ))}
      </div>
    </>
  );

  /**
   * @return {JSX.Element}
   */
  const renderCodeInput = () => (
    <>
      <p className="mb-0 mt-20 form-subtitle">
        Please enter the code we sent to your {selectedChannel.label}:
      </p>

      <div className="w-100 mt-20">
        <InputField
          id="code"
          type="text"
          value={codeValue}
          placeholder="Verification Code"
          className={`custom-input${error ? ' has-error-border' : ''}`}
          onChange={(e) => onChangeCodeInput(e)}
        />

        {error && <span className="text-red">{error}</span>}
      </div>

      <Button
        className="layover-popup__button layover-popup__button--dark mt-10 notification-code-btn notification-btn"
        disabled={codeValue.length === 0}
        onClick={onSendConfirm}
        dataQaId="qa_send_code_btn"
      >
        Verify
      </Button>

      <p>
        Didn't receive a code?{' '}
        <button
          className="resend-btn"
          type="button"
          onClick={() => onSendNotificationType(selectedChannel)}
          data-qa-id="qa_resend_code_btn"
        >
          Resend
        </button>
      </p>

      <p className="m-0">- or -</p>

      <Button
        className="notification-btn notification-btn-text"
        onClick={onChangeChannel}
        dataQaId="qa_change_channel_btn"
      >
        Try another channel
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={isModalOpened}
      onRequestClose={() => setIsModalOpened(false)}
      className="choose-chat-modal"
      size="lg"
      withCloseButton
      dataQaModalId="channel_varification_popup"
    >
      {isLoading && <OverlaySpinner />}

      <div className="form-title">Channel verification</div>

      <img src="/images/lp/promo2/hello.png" alt="img" className="form-image" />

      {!showInputCode ? renderChannels() : renderCodeInput()}
    </Modal>
  );
};

export default ErrorBoundaryDecorator()(ConfirmedChannelModal);
